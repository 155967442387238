import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    APP_BAR:{
      drawer: false,
      showBg: true,
      showSearch: false,
    },
    SEARCH:{
      timeline: 0,
      flags:[]
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
