<template>
  <div class="home">
    <v-container>
      <search-box class="cw-search-box">
        <template v-slot:title>
          <span class="text-h5 cw-home__title" style="margin-left:2rem"> Search </span>
        </template>
      </search-box>
      <div class="cw-hr_view">
        <span class="text-h5 cw-home__title"> Recommends </span>
        <v-row>
            <home-recommend-box v-for="item in 8" :key="item" :title="'TITLE: recommend'+item" rightLeft>
              <template v-slot:default>
                <span>这是一个用于 recommend{{item}} 的说明文字，这段文字将对内容进行简单介绍。</span>
              </template>
              <template v-slot:actions>
                <v-btn depressed>进入</v-btn>
              </template>
            </home-recommend-box>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import HomeRecommendBox from "../components/HomeRecommendBox.vue";
// @ is an alias to /src
import searchBox from "../components/SearchBox.vue";

export default {
  name: "Home",
  components: {
    searchBox,
    HomeRecommendBox,
  },
  mounted() {
    this.$store.state.APP_BAR.showBg = true;
    this.$store.state.APP_BAR.showSearch = false;
  },
};
</script>

<style lang="scss">
.cw-search-box {
  position: sticky;
  margin-top: 17.5vh;
  max-width: 600px;
  top: 74px;
  z-index: 1;
}
.cw-hr_view {
  position: sticky;
  top: 174px;
  margin-top: 22.5vh;
  padding: 2rem;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  overflow: hidden;
}
</style>