<template>
  <v-app :style="$store.state.APP_BAR.showBg ? appHomeBg : ''">
    <!--NAVDRAWER-->
    <nav-drawer :drawer="drawer"></nav-drawer>
    <!--NAVBAR-->
    <v-app-bar app :elevate-on-scroll="!$store.state.APP_BAR.showSearch" :extension-height="$store.state.APP_BAR.showSearch ? 58: 1">
      <v-app-bar-nav-icon @click.stop="$store.state.APP_BAR.drawer = !$store.state.APP_BAR.drawer">
        <v-icon v-if="$store.state.APP_BAR.drawer">menu_open</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Wiki Code</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text to="/about">
        <span>about</span>
      </v-btn>
      <v-btn icon>
        <v-icon>apps</v-icon>
      </v-btn>
      <!-- <template v-if="$store.state.APP_BAR.showSearch" v-slot:extension> -->
      <template v-slot:extension>
        <v-container>
          <v-slide-y-transition>
            <search-box v-if="$store.state.APP_BAR.showSearch" style="max-width: 600px; padding-bottom: 16px"></search-box>
          </v-slide-y-transition>
        </v-container>
      </template>
    </v-app-bar>
    <!--MAIN CONTENT-->
    <v-main>
      <v-scroll-y-transition hide-on-leave>
        <router-view></router-view>
      </v-scroll-y-transition>
      <div style="height: 44px;"></div>
      <v-footer absolute>
        Copyright &copy; 2021 Lonay.me.
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import navDrawer from "./components/Navigation.vue";
import SearchBox from './components/SearchBox.vue';

export default {
  name: "App",
  components: {
    navDrawer,
    SearchBox
  },
  data() {
    return {
      drawer: false,
      appHomeBg: {
        backgroundImage: "url('https://api.lonay.me/1/bing')",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
      },
    };
  },
};
</script>
<style lang="scss">
header {
  backdrop-filter: blur(10px);
  background-color: rgb(255 255 255 / 28%) !important;
}
</style>