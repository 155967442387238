<template>
  <v-navigation-drawer v-model="$store.state.APP_BAR.drawer" app>
    <v-list>
      <!-- <v-list-item class="px-2">
          <v-list-item-avatar>
          </v-list-item-avatar>
        </v-list-item> -->
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="text-h5">Welcome</v-list-item-title>
          <v-list-item-subtitle>Lonay code wiki v0.1</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item v-if="$route.path != '/'" link to="/">
        <v-list-item-icon>
          <v-icon>home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>首页</v-list-item-title>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>code</v-icon>
        </v-list-item-icon>
        <v-list-item-title>学习</v-list-item-title>
      </v-list-item>
      <v-list-item link disabled>
        <v-list-item-icon>
          <v-icon>book</v-icon>
        </v-list-item-icon>
        <v-list-item-title>记录</v-list-item-title>
        <v-list-item-icon>
          <v-icon small>lock</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>
    <v-subheader>Search Tools</v-subheader>
    <v-row class="my-0 mx-0">
      <v-col cols="12">
        <v-select
          v-model="$store.state.SEARCH.timeline"
          :items="timelineList"
          eager
          hide-details
          label="Set timeline"
          item-text="text"
          item-value="time"
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-switch v-model="$store.state.SEARCH.flags" label="1" value="1"></v-switch>
      </v-col>
      <v-col cols="6">
        <v-switch v-model="$store.state.SEARCH.flags" label="2" value="2"></v-switch>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-subheader>Links</v-subheader>
    <v-list dense>
      <v-list-item
        v-for="link in links"
        link
        :key="'link@' + link[1]"
        :href="`https://${link[1]}`"
        target="_black"
      >
        <v-list-item-title>{{ link[0] }}</v-list-item-title>
        <v-list-item-icon>
          <v-icon>open_in_new</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "navDrawer",
  data() {
    return {
      links: [
        ["Lonay", "lonay.me"],
        ["Anillc's Blog", "anillc.cn"],
      ],
      timelineList: [
        { text: "所有时间", time: 0 },
        { text: "过去一个月", time: 1 },
        { text: "过去六个月", time: 6 },
        { text: "过去一年", time: 12 },
      ],
    };
  },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>