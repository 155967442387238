<template>
  <div>
      <slot name="title"></slot>
      <v-text-field v-model="searchText" single-line solo clearable error hide-details append-icon="search" @click:append="goSearch()" @keyup.enter="goSearch()"></v-text-field>
  </div>
</template>

<script>
export default {
    data() {
        return {
            searchText: ''
        }
    },
    computed: {
        getSearchText(){
            return encodeURI(this.searchText);
        }
    },
    methods:{
        goSearch(){
            if(this.searchText){
                this.$router.replace({ path: '/search', query: { key:  this.searchText}})
            }else{
                console.log('[LONAY SEARCH] Fail search.')
            }
        }
    },
    mounted () {
        if(this.$route.query.key)
            this.searchText = this.$route.query.key;
    },
    props: {
        onText: {
            type: String,
            default: ''
        },
    },
};
</script>

<style lang="scss" scoped>
</style>