<template>
    <v-col cols="12" :lg="cols">
        <v-card>
            <v-img class="align-end" width="100%" aspect-ratio="0.9" :src="src" >
                <v-card-title style="background-color: rgb(255 255 255 / 28%);backdrop-filter: blur(10px);">{{title}}</v-card-title>
            </v-img>
            <v-card-text><slot></slot></v-card-text>
            <v-card-actions>
                <v-spacer v-if="rightLeft"></v-spacer>
                <slot name="actions"></slot>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
    export default {
        name: "homeRecommendBox",
        props: {
            cols: {
                type: Number,
                default: 2
            },
            title: {
                type: String,
                default: 'TITLE'
            },
            rightLeft:{
                type: Boolean,
                default: false
            },
            src: {
                type: String,
                default: 'https://i.loli.net/2021/01/14/ZY74Wi2ASklPFbK.jpg'
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>